var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v(" Разходи "),_c('v-spacer'),_c('FormInput',{staticStyle:{"flex":"1"},attrs:{"type":"text","i18n":{
        label: 'LABELS.SEARCH',
        placeholder: _vm.$t('LABELS.SEARCH')
      },"button":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('button',{staticClass:"btn btn-primary ml-5",on:{"click":_vm.saveAndAddRow}},[_vm._v(" Добави ред + ")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":"id","item-key":"id","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.totalPrice",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalPrice)+" лв. ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('router-link',{attrs:{"to":{
            name: 'edit-service-invoice-rows',
            params: { invoiceId: _vm.invoiceId, id: item.id }
          }}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("LABELS.EDIT")))])])],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }