<template>
  <v-card class="mt-5">
    <v-card-title>
      Разходи
      <v-spacer></v-spacer>
      <FormInput
        style="flex: 1"
        type="text"
        :i18n="{
          label: 'LABELS.SEARCH',
          placeholder: $t('LABELS.SEARCH')
        }"
        v-model="search"
        button
      >
      </FormInput>
      <button class="btn btn-primary ml-5" @click="saveAndAddRow">
        Добави ред +
      </button>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      sort-by="id"
      item-key="id"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:[`item.totalPrice`]="{ item }">
        {{ item.totalPrice }} лв.
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-end">
          <router-link
            :to="{
              name: 'edit-service-invoice-rows',
              params: { invoiceId: invoiceId, id: item.id }
            }"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" fab dark x-small v-bind="attrs" v-on="on">
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("LABELS.EDIT") }}</span>
            </v-tooltip>
          </router-link>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import FormInput from "@/view/content/forms/components/FormInput.vue";

export default {
  components: {
    FormInput
  },
  props: {
    form: Object,
    items: Array
  },
  data() {
    return {
      search: ""
    };
  },
  computed: {
    headers() {
      const headers = [
        {
          text: this.$t("FORM_LABELS.NAME"),
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("FORM_LABELS.CODE"),
          sortable: true,
          value: "code"
        },
        {
          text: this.$t("FORM_LABELS.MACHINE"),
          sortable: true,
          value: "machine"
        },
        {
          text: this.$t("FORM_LABELS.WAREHOUSE_ITEM"),
          sortable: true,
          value: "warehouseItem"
        },
        {
          text: this.$t("FORM_LABELS.QTY"),
          sortable: true,
          value: "totalQty"
        },
        {
          text: this.$t("FORM_LABELS.PRICE"),
          value: "price",
          sortable: true
        },
        {
          text: this.$t("FORM_LABELS.TOTAL_PRICE"),
          value: "totalPrice",
          sortable: true
        },
        {
          align: "right",
          text: this.$i18n.t("MENU.ITEM.ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ];

      return headers;
    },
    invoiceId() {
      return this.$route.params.id;
    }
  },
  methods: {
    saveAndAddRow() {
      this.$emit("add");
    }
  }
};
</script>

<style scoped>
.min-w-80 {
  min-width: 80px;
}
.work-table td,
.work-table th {
  padding: 0 15px;
}
</style>
